import { Outlet } from "react-router-dom";
import "./styles.scss";
import Container from "../../components/Container";

const Auth = () => {
  return (
    <main className="auth">
      <Container header={<h1>the glass factory</h1>}>
        <div className="center">
          <Outlet />
        </div>
      </Container>
    </main>
  );
};

export default Auth;
