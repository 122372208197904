import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { AppDispatch, RootState } from "../redux/store";
import { logout } from "../redux/auth";

const PrivateRoute: React.FC<{}> = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const auth = useSelector((state: RootState) => state.auth);
  const { isAuthenticated } = auth.data;

  useEffect(() => {
    if (!isAuthenticated) {
      dispatch(logout());
      navigate("/auth/login");
    }
  }, [isAuthenticated]);

  return isAuthenticated ? <Outlet /> : <Navigate to="/auth/login" />;
};

export default PrivateRoute;
