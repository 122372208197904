import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../../redux/store";
import { verifyEmail } from "../../../../../redux/auth/reducers/verifyEmail";
import onDispatchFulfilled from "../../../../../redux/utils/onDispatchFulfilled";
import Loading from "@/components/Loading";
import Error from "@/components/Error";

const VerifyEmail: React.FC<{}> = () => {
  const [searchParams] = useSearchParams();
  const sessionId = searchParams.get("sessionId");
  const emailToken = searchParams.get("emailToken");

  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const {
    api: {
      verifyEmail: { isLoading, error },
    },
  } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    const verify = async () => {
      const res = await dispatch(verifyEmail({ sessionId, emailToken }));
      onDispatchFulfilled(res, () => {
        navigate("/auth/register-phone");
      });
    };
    verify();
  }, [dispatch, navigate, sessionId, emailToken]);

  if (error) return <Error error={error} />;
  else if (isLoading) return <Loading />;
  return (
    <div>
      <h1>Verify Email</h1>
    </div>
  );
};

export default VerifyEmail;
