import { ActionReducerMapBuilder, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import TheGlassFactory from "../../../services/TheGlassFactory";
import { RootState } from "../../store";
import { ProductsState } from "..";
import { Product } from "../../../types/product";
import { orderBy } from "lodash";
import buildAsyncThunkReducer from "../../utils/buildAsyncThunkReducer";
import parseErrors from "../../utils/parseErrors";


const API = "deleteProduct"

export const deleteProduct = createAsyncThunk(
  API,
  async (id: string, { getState, rejectWithValue }) => {
    try {
      const { auth } = getState() as RootState

      const { data } =  await TheGlassFactory.request("DELETE", `/products/${id}`, {
        authState: auth,
        toast: true
      });

      return data
    } catch (error) {
      console.error(error);
      const parsedError = parseErrors(error, true)[0]
      return rejectWithValue(parsedError);
    }
  }
);

export const deleteProductReducer = (
  builder: ActionReducerMapBuilder<ProductsState>
) => {
  buildAsyncThunkReducer(
    builder,
    deleteProduct,
    (state: ProductsState, action: PayloadAction<Product>) => {
      state.api[API].isLoading = false;
      state.data.products = orderBy(state.data.products.filter((product: Product) => product.id !== action.payload.id), 'createdAt', 'desc')
    },
    API
  )
};

