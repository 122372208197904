import { useState, useEffect } from "react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import TheGlassFactory from "../../services/TheGlassFactory";
import "./styles.scss";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { toPennies } from "../../utils/toPennies";
import { emptyBasket } from "../../redux/basket";
import { updateOrder } from "../../redux/orders/reducers/updateOrder";
import { OrderStatus } from "../../types/product";
import Button from "../Button";

const cardStyle = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: "Arial, sans-serif",
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#32325d",
      },
    },
    invalid: {
      fontFamily: "Arial, sans-serif",
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

const PaymentForm = ({ cost, handleCreateOrder, onSuccess, onError }) => {
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [clientSecret, setClientSecret] = useState("");
  const stripe = useStripe();
  const elements = useElements();

  const auth = useSelector((state: RootState) => state.auth);
  const basket = useSelector((state: RootState) => state.basket.data);

  useEffect(() => {
    createPaymentIntent();
  }, [cost]);

  const createPaymentIntent = async () => {
    if (cost) {
      try {
        const res = await TheGlassFactory.request("POST", "/payments/intent", {
          authState: auth,
          payload: { cost: toPennies(basket.total) },
        });
        setClientSecret(res.data.client_secret);
      } catch (error) {
        console.error(error);
        setError("There was an error creating the payment intent");
      }
    }
  };

  const handleChange = async (e) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(e.empty);
    setError(e.error ? e.error.message : "");
    if (e.error) {
      toast.error(e.error.message);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setProcessing(true);
    const orderId = await handleCreateOrder();
    const payload = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
      },
    });
    if (payload.error) {
      toast.error(payload.error.message);
      setError(`Payment failed ${payload.error.message}`);
      setProcessing(false);
      onError(orderId);
    } else {
      setError(null);
      setProcessing(false);
      setSucceeded(true);
      onSuccess(orderId);
    }
  };

  return (
    <form className="paymentForm" onSubmit={handleSubmit}>
      {!succeeded ? (
        <>
          <CardElement
            id="card-element"
            className="cardElement"
            options={cardStyle}
            onChange={handleChange}
          />
          <button
            className="button primary wide"
            disabled={processing || disabled || succeeded}
          >
            <span>
              {processing ? <div className="spinner"></div> : "Pay now"}
            </span>
          </button>
          {/* Show any error that happens when processing the payment */}
          {error && (
            <div className="cardError" role="alert">
              {error}
            </div>
          )}
        </>
      ) : (
        <p className={succeeded ? "resultMessage" : "resultMessage hidden"}>
          Payment succeeded, see the result in your
          <a href={`https://dashboard.stripe.com/test/payments`}>
            {" "}
            Stripe dashboard.
          </a>{" "}
          Refresh the page to pay again.
        </p>
      )}
    </form>
  );
};

export default PaymentForm;
