import React, { useEffect, useState } from "react";
import { getProducts } from "../../../../redux/products/reducers/getProducts";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../redux/store";
import Loading from "../../../../components/Loading";
import Container from "../../../../components/Container";
import Button from "../../../../components/Button";
import { useNavigate } from "react-router-dom";
import ProductContainer from "../../components/Product";

const Menu: React.FC<{}> = () => {
  const [allowProductDeletion, setAllowProductDeletion] = useState(false);
  const {
    data,
    api: {
      getProducts: { isLoading, error },
    },
  } = useSelector((state: RootState) => state.products);
  const { user } = useSelector((state: RootState) => state.auth.data);

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getProducts());
  }, [dispatch]);

  return (
    <Container header={<h1>the glass factory</h1>}>
      <div className="menu">
        {(data.products.length === 0 && isLoading) || error ? (
          <Loading center error={error} />
        ) : (
          <>
            <div className="products">
              {data.products.map((product, i) => (
                <ProductContainer
                  key={i}
                  product={product}
                  allowProductDeletion={allowProductDeletion}
                />
              ))}
            </div>
            {user.groups.includes("admin") && (
              <div className="row-center">
                <Button
                  label={allowProductDeletion ? "Cancel" : "Delete"}
                  type="secondary"
                  onClick={() => setAllowProductDeletion(!allowProductDeletion)}
                  isWide
                />
                <Button
                  label="Create"
                  onClick={() => navigate("/product/create")}
                  isWide
                />
              </div>
            )}
          </>
        )}
      </div>
    </Container>
  );
};

export default Menu;
