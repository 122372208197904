import "./styles.scss";

interface Props {
  Icon?: React.FC;
  label: string;
  onClick: (e: React.FormEvent) => void;
}

const Tile: React.FC<Props> = ({ Icon, label, onClick }) => {
  return (
    <div className="tile" onClick={onClick}>
      {Icon && <Icon />}
      <span>{label}</span>
    </div>
  );
};

export default Tile;
