export interface Product {
  id?: string;
  name: string;
  description: string;
  type: "Drinks" | "Food" | "Special";
  cost: number;
  createdAt?: string;
}

export interface ProductOrder extends Product { 
  quantity: number;
}

export enum OrderStatus {
  PENDING = "Pending",
  SUCCESSFUL = "Successful",
  FAILED = "Failed"
}

export interface Order {
  id?: string;
  status?: OrderStatus;
  total: number;
  transactionFee: number;
  subtotal: number;
  quantity: number;
  productOrders: ProductOrder[];
  createdAt?: string;
}
