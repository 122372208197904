import "./styles.scss";

interface Props {
  label: string;
  type?: "primary" | "secondary";
  center?: boolean;
  onClick: (e: React.FormEvent) => void;
  isWide?: boolean;
}

const Button: React.FC<Props> = ({
  label,
  type = "primary",
  center,
  onClick,
  isWide = false,
}) => {
  if (center) {
    return (
      <div className="center">
        <button
          className={`button ${type} ${isWide ? "wide" : ""}`}
          onClick={onClick}
        >
          {label}
        </button>
      </div>
    );
  }
  return (
    <button
      className={`button ${type} ${isWide ? "wide" : ""}`}
      onClick={onClick}
    >
      {label}
    </button>
  );
};

export default Button;
