import { Toaster } from "react-hot-toast";
import { Provider } from "react-redux";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import useVH from "react-viewport-height";

import { PersistGate } from "redux-persist/integration/react";

import Router from "./router";
import "./styles/global.scss";
import { store, persistor } from "./redux/store";

const stripePublicKey =
  process.env.REACT_APP_ENV === "prod"
    ? process.env.REACT_APP_STRIPE_LIVE_PUBLIC_KEY
    : process.env.REACT_APP_STRIPE_TEST_PUBLIC_KEY;

const stripePromise = loadStripe(stripePublicKey);

function App() {
  useVH();

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Elements stripe={stripePromise}>
          <div className="app">
            <Toaster position="bottom-center" />
            <Router />
          </div>
        </Elements>
      </PersistGate>
    </Provider>
  );
}

export default App;
