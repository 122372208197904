import { Route, Routes } from "react-router-dom";

import PrivateRoute from "./PrivateRoute";
import Auth from "../pages/Auth";

import Home from "../pages/Home";
import PageNotFound from "../pages/PageNotFound";

import Menu from "../pages/Home/pages/Menu";
import CreateProduct from "../pages/Home/pages/CreateProduct";
// import Basket from "../pages/Home/pages/Basket";
import Checkout from "../pages/Home/pages/Checkout";
import Orders from "../pages/Home/pages/Orders";
import Order from "../pages/Home/pages/Order";
import Profile from "../pages/Home/pages/Profile";
import Register from "../pages/Auth/pages/onboarding/Register";
import VerifyEmail from "../pages/Auth/pages/onboarding/VerifyEmail";
import ConfirmRegister from "../pages/Auth/pages/onboarding/ConfirmRegister";
import Login from "../pages/Auth/pages/login/Login";
import ConfirmOTP from "../pages/Auth/pages/login/ConfirmOTP";

const Router = () => {
  return (
    <Routes>
      <Route element={<PrivateRoute />}>
        <Route path="" element={<Home />}>
          <Route index path="/" element={<Menu />} />
          <Route index path="/product/create" element={<CreateProduct />} />
          {/* <Route index path="/basket" element={<Basket />} /> */}
          <Route index path="/checkout" element={<Checkout />} />

          <Route index path="/profile" element={<Profile />} />
          <Route index path="/orders" element={<Orders />} />
          <Route index path="/orders/:orderId" element={<Order />} />
        </Route>
      </Route>

      <Route path="auth" element={<Auth />}>
        <Route index path="register" element={<Register />} />
        <Route index path="verify-email" element={<VerifyEmail />} />
        <Route index path="confirm-register" element={<ConfirmRegister />} />

        <Route index path="login" element={<Login />} />
        <Route path="confirmOTP" element={<ConfirmOTP />} />
      </Route>

      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

export default Router;
