import { ActionReducerMapBuilder, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import TheGlassFactory from "../../../services/TheGlassFactory";

import { AuthState } from "..";
import buildAsyncThunkReducer from "../../utils/buildAsyncThunkReducer";
import parseErrors from "../../utils/parseErrors";

interface ConfirmRegister {
  email: string;
  authCode: string
}
const API = "confirmRegister";
export const confirmRegister = createAsyncThunk(
  API,
  async (data: ConfirmRegister, { rejectWithValue }) => {
    try {
      const { email, authCode } = data
       
      await TheGlassFactory.request("POST", "/confirmRegister", {
        payload: { email, authCode },
        toast: true
      });
    } catch (error) {
      console.error(error);
      const parsedError = parseErrors(error, true)[0]
      return rejectWithValue(parsedError);
    }
  }
);

export const confirmRegisterReducer = (
  builder: ActionReducerMapBuilder<AuthState>
) => {
  buildAsyncThunkReducer(
    builder,
    confirmRegister,
    (state: AuthState, action: PayloadAction<string>) => {
      state.api[API].isLoading = false;
    },
    API
  )
};
