import { Link } from "react-router-dom";

interface Props {
  toPath: string;
  currentPath: string;
  Svg: React.FC;
}
const NavLink: React.FC<Props> = ({ toPath, currentPath, Svg }) => {
  const isActive = currentPath === toPath;
  return (
    <Link to={toPath}>
      <div className={`navLink ${isActive && "active"}`}>
        <Svg />
      </div>
    </Link>
  );
};

export default NavLink;
