import { ActionReducerMapBuilder, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import TheGlassFactory from "../../../services/TheGlassFactory";
import { RootState } from "../../store";

import { Order } from "../../../types/product";
import { OrdersState } from "..";
import parseErrors from "../../utils/parseErrors";
import buildAsyncThunkReducer from "../../utils/buildAsyncThunkReducer";

interface UpdateOrder {
  id: string;
  status: string;
}

const API = "updateOrder"

export const updateOrder = createAsyncThunk(
  API,
  async ({ id, status }: UpdateOrder, { getState, rejectWithValue }) => {
    try {
      const { auth } = getState() as RootState

      const { data } =  await TheGlassFactory.request("PUT", `/orders/${id}`, {
        authState: auth,
        payload: { status }
      });

      return data
    } catch (error) {
      console.error(error);
      const parsedError = parseErrors(error, true)[0]
      return rejectWithValue(parsedError);
    }
  }
);

export const updateOrderReducer = (
  builder: ActionReducerMapBuilder<OrdersState>
) => {
  buildAsyncThunkReducer(
    builder,
    updateOrder,
    (state, action: PayloadAction<Order>) => {
      const updateOrder = action.payload
      state.api[API].isLoading = false;
      state.data.orders = state.data.orders.map((order) => order.id === updateOrder.id ? updateOrder : order)
    },
    API
  )
};
