import { DateTime } from 'luxon'

export const toFullDateTime = (date: Date | string) => {
    return DateTime.fromISO(date.toString()).toFormat("dd LLL yyyy HH:mm:ss")
}

export const toLongDate = (date: Date | string) => {
    return DateTime.fromISO(date.toString()).toFormat("dd LLL yyyy")
}



export const toDate = (date: Date | string) => {
    return DateTime.fromISO(date.toString()).toFormat('dd/MM/yy')
}

export const toTime = (date: Date | string) => {
    return DateTime.fromISO(date.toString()).toFormat('HH:mm')
}
