import { ActionReducerMapBuilder, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import TheGlassFactory from "../../../services/TheGlassFactory";
import { AuthState } from "..";
import buildAsyncThunkReducer from "../../utils/buildAsyncThunkReducer";
import parseErrors from "../../utils/parseErrors";

interface Login {
  email: string;
  password: string;
}

const API = "login";
export const login = createAsyncThunk(
  API,
  async (data: Login, { rejectWithValue }) => {
    try {
      const { email, password } = data
      const { data: { session, userId } } =  await TheGlassFactory.request("POST", "/login", {
        payload: { email, password },
        toast: true
      });

      return {session, userId}
    } catch (error) {
      console.error(error);
      const parsedError = parseErrors(error, true)[0]
      return rejectWithValue(parsedError);
    }
  }
);

export const loginReducer = (
  builder: ActionReducerMapBuilder<AuthState>
) => {
  buildAsyncThunkReducer(
    builder,
    login,
    (state: AuthState, action: PayloadAction<any>) => {
      state.api[API].isLoading = false;
      state.data.session = action.payload.session
      state.data.user.id = action.payload.userId
    },
    API
  )
};
