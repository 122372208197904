import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { AppDispatch, RootState } from "../../../../redux/store";
import toGBP from "../../../../utils/toGBP";
import PaymentForm from "../../../../components/PaymentForm";
import Container from "../../../../components/Container";
import { postOrder } from "../../../../redux/orders/reducers/postOrder";
import ProductContainer from "../../components/Product";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { emptyBasket } from "../../../../redux/basket";
import { updateOrder } from "../../../../redux/orders/reducers/updateOrder";
import { OrderStatus } from "../../../../types/product";
import GoBack from "../../components/GoBack";

const Checkout: React.FC<{}> = () => {
  const {
    data: basket,
    // api: {
    //   postOrder: { isLoading, error },
    // },
  } = useSelector((state: RootState) => state.basket);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const handleCreateOrder = async () => {
    const res = await dispatch(
      postOrder({
        subtotal: basket.subtotal,
        transactionFee: basket.transactionFee,
        total: basket.total,
        quantity: basket.quantity,
        productOrders: basket.productOrders,
      })
    );
    return res.payload.id;
  };

  const onSuccess = async (orderId) => {
    navigate(`/orders/${orderId}`);
    await dispatch(
      updateOrder({ id: orderId, status: OrderStatus.SUCCESSFUL })
    );
    toast.success("Successfully received payment");
    dispatch(emptyBasket());
  };

  const onError = async (orderId) => {
    await dispatch(updateOrder({ id: orderId, status: OrderStatus.FAILED }));
  };

  return (
    <Container header={<h1>Checkout</h1>}>
      <div className="basket">
        {basket.quantity === 0 ? (
          <GoBack message="your basket is empty" />
        ) : (
          <>
            <div className="products">
              {basket.productOrders.map((product, i) => (
                <ProductContainer key={i} product={product} />
              ))}
            </div>
            <div className="details">
              <div className="detail">
                <span>Subtotal</span>
                <span>{toGBP(basket.subtotal)}</span>
              </div>
              <div className="detail">
                <span>Transaction</span>
                <span>{toGBP(basket.transactionFee)}</span>
              </div>
              <div className="detail">
                <span>Service</span>
                <span>Free</span>
              </div>
              <div className="detail total">
                <span>Total</span>
                <span>{toGBP(basket.total)}</span>
              </div>
            </div>
            <PaymentForm
              cost={basket.total}
              handleCreateOrder={handleCreateOrder}
              onSuccess={onSuccess}
              onError={onError}
            />
          </>
        )}
      </div>
    </Container>
  );
};

export default Checkout;
