import { ERROR_MESSAGE } from "../../constants/errors";
import { toast } from "react-hot-toast";

const parseErrors = (error: any, toaster?: boolean): string[] => {
  try {
    if (error instanceof Error) {
      const parsedErrors = JSON.parse(error.message);
      const errors = parsedErrors.map((error: any) => {
        toaster && toast.error(error.message)
        return error.message
      });
      return errors;
    }
  } 
  catch (error) {
    console.error(error);
  }

  return [ERROR_MESSAGE];
};


export default parseErrors;
