interface Props {
  error?: string;
}

const Error: React.FC<Props> = ({ error }) => {
  if (error) {
    return (
      <div>
        <p>{error}</p>
      </div>
    );
  }
  return <></>;
};

export default Error;
