import { createSlice } from "@reduxjs/toolkit";
import { registerReducer } from "./reducers/register";
import { confirmRegisterReducer } from "./reducers/confirmRegister";
import { loginReducer } from "./reducers/login";
import { confirmOTPReducer } from "./reducers/confirmOTP";
import { initialApiState } from "../constants";
import { ReduxState } from "../types";
import { verifyEmailReducer } from "./reducers/verifyEmail";

export interface User {
  id: string,
  email: string,
  phoneNumber: string,
  groups: string[]
}
export interface AuthState extends ReduxState {
  data: {
      isAuthenticated: boolean,
      session?: string,
      accessToken?: string,
      refreshToken?: string,
      tokenId?: string,
      user?: User
  }
}
  

const initialState = {
  data: {
    isAuthenticated: false,
    session: "",
    accessToken: "",
    refreshToken: "",
    tokenId: "",
    user: {
      id: "",
      email: "",
      phoneNumber: "",
      groups: []
    }
  },
  api: {
    register: initialApiState,
    verifyEmail: initialApiState,
    confirmRegister: initialApiState,
    
    login: initialApiState,
    confirmOTP: initialApiState
  }
}

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      state.data = initialState.data
    }
  },
  extraReducers: (builder) => {
    registerReducer(builder);
    verifyEmailReducer(builder);
    confirmRegisterReducer(builder);

    loginReducer(builder);
    confirmOTPReducer(builder);
  },

});

export const { logout } = authSlice.actions;
export default authSlice.reducer;
