import { apiCacheInMins } from "../../constants/api";
import { RootState } from "../store";


// const skipApiIfCached = (apiName) => {
//     return {
//         condition: (_, { getState }) => {
//           const state = getState() as RootState;
//           const api = state.products.api[apiName];
    
//           const currentTimestamp = Date.now();
//           const cacheDuration = apiCacheInMins * 60000; // Cache duration in milliseconds (e.g., 1 minute)
    
//           // Use cached data if it exists and hasn't expired
//           if (
//             currentTimestamp - api.lastFetchTimestamp < cacheDuration
//           ) {
//             return false; // Skip the API call
//           }
//         },
//       }
// }

const skipApiIfCached = (getState, apiName) => {

  const state = getState() as RootState;
  const api = state.products.api[apiName];

  const currentTimestamp = Date.now();
  const cacheDuration = apiCacheInMins * 60000; // Cache duration in milliseconds (e.g., 1 minute)

  // Use cached data if it exists and hasn't expired
  if (
    currentTimestamp - api.lastFetchTimestamp < cacheDuration
  ) {
    return false; // Skip the API call
  }
}




export default skipApiIfCached;