import { useState } from "react";
import { Form, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "@/redux/store";
import { confirmRegister } from "@/redux/auth/reducers/confirmRegister";
import Loading from "@/components/Loading";
import onDispatchFulfilled from "@/redux/utils/onDispatchFulfilled";
import Input from "@/components/Form/components/Input";
import FormButton from "@/components/Form/components/FormButton";

const ConfirmRegister: React.FC<{}> = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const {
    data: { user },
    api: {
      confirmRegister: { isLoading },
    },
  } = useSelector((state: RootState) => state.auth);

  const [otp, setOTP] = useState("");

  const onSubmit = async (e) => {
    e.preventDefault();

    const res = await dispatch(
      confirmRegister({ email: user.email, authCode: otp })
    );
    onDispatchFulfilled(res, () => {
      navigate("/");
    });
  };

  if (isLoading) return <Loading />;
  return (
    <Form>
      <Input
        name="otp"
        placeholder="OTP"
        onChange={(e) => setOTP(e.target.value)}
      />
      <FormButton label="Confirm" onClick={onSubmit} />
    </Form>
  );
};

export default ConfirmRegister;
