import { ActionReducerMapBuilder, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import TheGlassFactory from "../../../services/TheGlassFactory";
import { AuthState } from "..";
import parseErrors from "../../utils/parseErrors";
import buildAsyncThunkReducer from "../../utils/buildAsyncThunkReducer";

interface Register {
  email: string;
  password: string;
  phoneNumber: string;
}

const API = "register";
export const register = createAsyncThunk(
  API,
  async (data: Register, { rejectWithValue }) => {
    try {
      const { email, password, phoneNumber } = data
       await TheGlassFactory.request("POST", "/register", {
        payload: { email, password, phoneNumber },
        toast: true
      });

      return email
    } catch (error) {
      console.error(error);
      const parsedError = parseErrors(error, true)[0]
      return rejectWithValue(parsedError);
    }
  }
);

export const registerReducer = (
  builder: ActionReducerMapBuilder<AuthState>
) => {
  buildAsyncThunkReducer(
    builder,
    register,
    (state: AuthState, action: PayloadAction<string>) => {
      state.api[API].isLoading = false;
      state.data.user.email = action.payload
    },
    API
  )
};
