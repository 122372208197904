import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { AppDispatch } from "../../../../redux/store";
import Container from "../../../../components/Container";
import { logout } from "../../../../redux/auth";
import Tile from "../../../../components/Tile";
import { ReactComponent as OrdersSVG } from "../../../../assets/svgs/orders.svg";
import { ReactComponent as TokensSVG } from "../../../../assets/svgs/tokens.svg";
import { ReactComponent as ArrowSVG } from "../../../../assets/svgs/arrow.svg";

const Profile: React.FC<{}> = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  return (
    <Container header={<h1>Profile</h1>}>
      <Tile
        Icon={OrdersSVG}
        label="my orders"
        onClick={() => navigate("/orders")}
      />
      <Tile Icon={TokensSVG} label="tokens" onClick={() => {}} />
      <Tile Icon={ArrowSVG} label="logout" onClick={() => dispatch(logout())} />
    </Container>
  );
};

export default Profile;
