import { ActionReducerMapBuilder, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import TheGlassFactory from "../../../services/TheGlassFactory";
import { RootState } from "../../store";

import { Order } from "../../../types/product";
import { OrdersState } from "..";
import { orderBy } from "lodash";
import parseErrors from "../../utils/parseErrors";
import buildAsyncThunkReducer from "../../utils/buildAsyncThunkReducer";


const API = "postOrder"

export const postOrder = createAsyncThunk(
  API,
  async (order: Order, { getState, rejectWithValue }) => {
    try {
      const { auth } = getState() as RootState
      const { data } =  await TheGlassFactory.request("POST", "/orders", {
        authState: auth,
        payload: order
      });
      const orderWithUpdatedDates = {
        ...data,
        createdAt: new Date(data.createdAt).toISOString()
      }

      return orderWithUpdatedDates
    } catch (error) {
      console.error(error);
      const parsedError = parseErrors(error, true)[0]
      return rejectWithValue(parsedError);
    }
  }
);

export const postOrderReducer = (
  builder: ActionReducerMapBuilder<OrdersState>
) => {
  buildAsyncThunkReducer(
    builder,
    postOrder,
    (state, action: PayloadAction<Order>) => {
      state.api[API].isLoading = false;
      state.data.orders = orderBy([...state.data.orders, action.payload], "createdAt", "desc")
    },
    API
  )
};
