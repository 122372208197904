import { combineReducers } from "@reduxjs/toolkit";
import authReducer from './auth/index'
import productsReducer from './products/index'
import basketReducer from './basket/index'
import ordersReducer from './orders/index'

const rootReducer = combineReducers({
  auth: authReducer,
  products: productsReducer,
  basket: basketReducer,
  orders:ordersReducer
});

export default rootReducer;