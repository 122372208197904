import axios from "axios";

import encodeQueryStringParameters from "./utils/encodeQueryStringParameters";
import { Dictionary, Endpoint, HTTPMethods } from "../../types/general";

import { toast } from "react-hot-toast";
import { AuthState } from "../../redux/auth";



export const API_URL = process.env.REACT_APP_API_URL;
export const ENV = process.env.REACT_APP_ENV;

const SUCCESS_CODES = [200, 204];

export default class TheGlassFactory {
  static async request(
    method: HTTPMethods,
    endpoint: Endpoint,
    options?: {
      payload?: any;
      queryStringParams?: Dictionary;
      authState?: AuthState;
      toast?: boolean;
    }
  ) {
    let url = `${API_URL}${endpoint}`;

    if (options?.queryStringParams) {
      url = `${url}?${encodeQueryStringParameters(options.queryStringParams)}`;
    }

    const requestOptions: {
      url: string;
      method: HTTPMethods;
      headers: {
        "Content-Type": string;
        Authorization?: string;
      };
      body?: string;
      data?: any;
      meta?: any;
      validateStatus: () => boolean;
    } = {
      url,
      method: method || "GET",
      headers: {
        "Content-Type": "application/json",
      },
      validateStatus: () => true, // Prevent Axios from throwing errors
    };

    if (options?.payload) {
      delete options.payload["id"];
      delete options.payload["updatedAt"];
      delete options.payload["createdAt"];
      requestOptions.data = options.payload;
    }

    if (options?.authState) {
      const { tokenId } = options.authState.data;

      if (!tokenId) {
        throw new Error("Unable to fetch auth tokens");
      }
      requestOptions.headers.Authorization = tokenId;
    }

    const response = await axios(requestOptions);

    const requestWasSuccessful =
      response.data.success ||
      (response.data?.success === undefined &&
        SUCCESS_CODES.includes(response.status));

    if (!requestWasSuccessful) {
      if (response.data.errors) {
        throw new Error(JSON.stringify(response.data.errors));
      }
      if (response.data.message) {
        throw new Error(response.data.message);
      }
      throw new Error("Unknown API error");
    }

    if (options?.toast) {
      toast.success(response.data.message);
    }

    return {
      data: response.data.data,
      message: response.data.message,
  }
}};