import { Outlet } from "react-router-dom";
import Nav from "../../components/Nav";
import "./styles.scss";

interface Props {}

const Home: React.FC<Props> = () => {
  return (
    <main className="home">
      <Nav />
      <Outlet />
    </main>
  );
};

export default Home;
