import "./styles.scss";

interface Props {
  error?: string;
  center?: boolean;
}

const Loading: React.FC<Props> = ({ error, center }) => {
  if (error) {
    return (
      <div>
        <p>{error}</p>
      </div>
    );
  } else if (center) {
    return (
      <div className="loading-center">
        <div className="loading">
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    );
  }
  return (
    <div className="loading">
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default Loading;
