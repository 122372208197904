import { ActionReducerMapBuilder, PayloadAction } from "@reduxjs/toolkit";

const buildAsyncThunkReducer = (
    builder: ActionReducerMapBuilder<any>,
    asyncThunk: any,
    fulfilledCaseReducer: any,
    api?: string
  ) => {
    return builder
      .addCase(asyncThunk.pending, (state: any) => {
        if (api) {
          state.api[api] = {
            isLoading: true,
            error: undefined,
          };
        }
      })
      .addCase(
        asyncThunk.rejected,
        (state: any, action: PayloadAction<string>) => {
          if (api) {
            state.api[api] = {
              isLoading: false,
              error: action.payload,
            };
          }
        }
      )
      .addCase(asyncThunk.fulfilled, fulfilledCaseReducer);
};

export default buildAsyncThunkReducer;