import React, { useState, useEffect } from "react";

import "./styles.scss";

import { useDispatch, useSelector } from "react-redux";
import { updateBasket } from "../../../../redux/basket";
import { AppDispatch, RootState } from "../../../../redux/store";
import toGBP from "../../../../utils/toGBP";
import Button from "../../../../components/Button";
import { deleteProduct } from "../../../../redux/products/reducers/deleteProduct";
import { Product, ProductOrder } from "../../../../types/product";
import { to2dp } from "../../../../utils/to2dp";

const ProductContainer: React.FC<{
  product: ProductOrder | Product;
  hideQuantityButtons?: boolean;
  allowProductDeletion?: boolean;
}> = ({ product, hideQuantityButtons, allowProductDeletion }) => {
  const [item, setItem] = useState(() => {
    const initialQuantity = "quantity" in product ? product.quantity : 0;
    return {
      ...product,
      quantity: initialQuantity,
      totalCost: to2dp(product.cost * initialQuantity),
    };
  });

  const dispatch = useDispatch<AppDispatch>();
  const { data: basket } = useSelector((state: RootState) => state.basket);

  const handleIncrement = () => {
    const quantity = item.quantity + 1;
    const totalCost = to2dp(item.cost * quantity);
    const updatedProd = {
      ...item,
      quantity: quantity,
      totalCost: totalCost,
    };
    setItem(updatedProd);
    dispatch(updateBasket(updatedProd));
  };

  const handleDecrement = () => {
    if (item.quantity > 0) {
      const quantity = item.quantity - 1;
      const totalCost = to2dp(item.cost * quantity);
      const updatedProd = {
        ...item,
        quantity: quantity,
        totalCost: totalCost,
      };
      setItem(updatedProd);
      dispatch(updateBasket(updatedProd));
    }
  };

  useEffect(() => {
    const productInBasket = basket.productOrders.find(
      (basketProduct) => basketProduct.id === product.id
    );
    if (productInBasket) {
      setItem(productInBasket);
    }
  }, [basket, product.id]);

  return (
    <div className="product box">
      <div className="info">
        <h3>{product.name}</h3>
        <span>{product.description}</span>
      </div>

      {allowProductDeletion ? (
        <Button
          label="Delete"
          type="secondary"
          onClick={() => dispatch(deleteProduct(product.id))}
        />
      ) : (
        <div
          className={`quantityAndCost ${
            hideQuantityButtons ? "hideQuantityButtons" : ""
          }`}
        >
          {!hideQuantityButtons && (
            <div className="quantity">
              <Button label="+" type="primary" onClick={handleIncrement} />
              {item.quantity > 0 && (
                <>
                  <span>{item.quantity}</span>
                  <Button
                    label="-"
                    type="secondary"
                    onClick={handleDecrement}
                  />
                </>
              )}
            </div>
          )}

          <div className="cost">
            {item.totalCost > 0 ? (
              <mark>{toGBP(item.totalCost)}</mark>
            ) : (
              toGBP(product.cost)
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductContainer;
