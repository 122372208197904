import "./styles.scss";

interface Props {
  name: string;
  value?: string | number;
  label?: string;
  type?: string;
  placeholder?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const Input: React.FC<Props> = ({
  name,
  value,
  label,
  type,
  placeholder,
  onChange,
}) => (
  <div className="formField">
    <label>{label}</label>
    <input
      className={`input ${type}`}
      defaultValue={value}
      name={name}
      type={type}
      placeholder={placeholder}
      onChange={onChange}
    />
  </div>
);

export default Input;
