import { ActionReducerMapBuilder, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import TheGlassFactory from "../../../services/TheGlassFactory";
import { RootState } from "../../store";
import { OrdersState } from "..";
import { Order } from "../../../types/product";
import { orderBy } from "lodash";
import buildAsyncThunkReducer from "../../utils/buildAsyncThunkReducer";
import parseErrors from "../../utils/parseErrors";

const API = "getOrders"

export const getOrders = createAsyncThunk(
  API,
  async (params, { getState, rejectWithValue }) => {
    try {
      const { auth } = getState() as RootState
      const { data } =  await TheGlassFactory.request("GET", "/orders", {
        authState: auth
      });

      return data
    } catch (error) {
      console.error(error);
      const parsedError = parseErrors(error, true)[0]
      return rejectWithValue(parsedError);
    }
  }
);

export const getOrdersReducer = (
  builder: ActionReducerMapBuilder<OrdersState>
) => {
  buildAsyncThunkReducer(
    builder,
    getOrders,
    (state, action: PayloadAction<Order[]>) => {
      state.api[API].isLoading = false;
      state.data.orders = orderBy(action.payload, "createdAt", "desc")
    },
    API
  )
};
