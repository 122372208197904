import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import Container from "../../../../components/Container";
import { useParams } from "react-router-dom";
import { toLongDate, toTime } from "../../../../utils/dates";
import toGBP from "../../../../utils/toGBP";
import ProductContainer from "../../components/Product";

const Order: React.FC<{}> = () => {
  let { orderId } = useParams();
  const { data: orders } = useSelector((state: RootState) => state.orders);

  const order = orders.orders.find((order) => order.id === orderId);

  if (!order) {
    return <p>Order not found</p>;
  }

  return (
    <Container
      header={
        <div className="orderHeader">
          <h1>{toLongDate(order.createdAt)}</h1>
          <h1>@ {toTime(order.createdAt)}</h1>
        </div>
      }
    >
      <div className="order">
        <div className="products">
          {order.productOrders.map((productOrder, i) => (
            <ProductContainer
              key={i}
              product={productOrder}
              hideQuantityButtons
            />
          ))}
        </div>
        <div className="details">
          <div className="detail">
            <span>Status</span>
            <span>
              <mark className={order.status.toLowerCase()}>{order.status}</mark>
            </span>
          </div>
          <div className="detail">
            <span>Subtotal</span>
            <span>{toGBP(order.subtotal)}</span>
          </div>
          <div className="detail">
            <span>Transaction Fee</span>
            <span>{toGBP(order.transactionFee)}</span>
          </div>
          <div className="detail">
            <span>Service</span>
            <span>Free</span>
          </div>
          <div className="detail total">
            <span>Total</span>
            <span>{toGBP(order.total)}</span>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Order;
