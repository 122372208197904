import Form from "@/components/Form";
import FormButton from "@/components/Form/components/FormButton";
import Input from "@/components/Form/components/Input";
import Loading from "@/components/Loading";
import { login } from "@/redux/auth/reducers/login";
import { AppDispatch, RootState } from "@/redux/store";
import onDispatchFulfilled from "@/redux/utils/onDispatchFulfilled";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

const Login: React.FC<{}> = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const {
    data: { isAuthenticated },
    api: {
      login: { isLoading },
    },
  } = useSelector((state: RootState) => state.auth);

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const onSubmit = async (e) => {
    e.preventDefault();

    const res = await dispatch(login(formData));
    onDispatchFulfilled(res, () => {
      navigate("/auth/confirmOTP");
    });
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  useEffect(() => {
    isAuthenticated && navigate("/");
  }, [navigate, isAuthenticated]);

  if (isLoading) return <Loading />;
  return (
    <Form>
      <Input name="email" placeholder="Email" onChange={onChange} />
      <Input
        name="password"
        type="password"
        placeholder="Password"
        onChange={onChange}
      />
      <FormButton label="Login" onClick={onSubmit} />
      <span>
        Don't have an account? <Link to="/auth/register">Register</Link>
      </span>
    </Form>
  );
};

export default Login;
