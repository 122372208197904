import React, { useState } from "react";
import { getProducts } from "../../../../redux/products/reducers/getProducts";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../redux/store";
import Loading from "../../../../components/Loading";
import Container from "../../../../components/Container";
import Button from "../../../../components/Button";
import Input from "../../../../components/Form/components/Input";
import TextArea from "../../../../components/Form/components/TextArea";
import { postProduct } from "../../../../redux/products/reducers/postProduct";
import Form from "../../../../components/Form";
import { useNavigate } from "react-router-dom";
import parseFormValue from "../../../../services/TheGlassFactory/utils/parseFormValue";

const initialFormData = Object.freeze({
  name: "",
  description: "",
  type: "Drinks",
  cost: "",
});

const CreateProduct: React.FC<{}> = () => {
  const [product, setProduct] = useState(initialFormData);

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const {
    api: {
      postProduct: { isLoading },
    },
  } = useSelector((state: RootState) => state.products);

  const onChange = (e) => {
    const { name, value, type } = e.target;
    setProduct({ ...product, [name]: parseFormValue(value, type) });
  };

  return (
    <Container header={<h1>Create Product</h1>}>
      <Form>
        <Input
          name="name"
          label="Name"
          onChange={onChange}
          value={product.name}
        />
        <TextArea
          name="description"
          label="Description"
          onChange={onChange}
          value={product.description}
        />
        <Input
          name="cost"
          label="Cost (£)"
          onChange={onChange}
          type="number"
          value={product.cost}
        />
      </Form>
      {isLoading ? (
        <Loading center />
      ) : (
        <div className="row-center">
          <Button
            label="Back"
            type="secondary"
            onClick={() => navigate("/")}
            isWide
          />
          <Button
            label="Save"
            type="primary"
            onClick={() => {
              dispatch(postProduct(product));
              navigate("/");
              dispatch(getProducts());
            }}
            isWide
          />
        </div>
      )}
    </Container>
  );
};

export default CreateProduct;
