import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import TheGlassFactory from "../../../services/TheGlassFactory";
import { AuthState } from "..";
import buildAsyncThunkReducer from "../../utils/buildAsyncThunkReducer";
import parseErrors from "../../utils/parseErrors";

interface VerifyEmail {
  sessionId: string;
  emailToken: string;
}

const API = "verifyEmail";
export const verifyEmail = createAsyncThunk(
  API,
  async (params: VerifyEmail, { rejectWithValue }) => {
    try {
      const { sessionId, emailToken } = params
      console.log("params", params)
      const { data: { session, userId } } =  await TheGlassFactory.request("POST", "/verifyEmail", {
        payload: { sessionId, emailToken },
        toast: true
      });
      return { session, userId }

    } catch (error) {
      console.error(error);
      const parsedError = parseErrors(error, true)[0]
      return rejectWithValue(parsedError);
    }
  }
);

export const verifyEmailReducer = (
  builder: ActionReducerMapBuilder<AuthState>
) => {
  buildAsyncThunkReducer(
    builder,
    verifyEmail,
    (state: AuthState) => {
      state.api[API].isLoading = false;
    },
    API
  )
};
