import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ProductOrder } from "../../types/product";
import { stripeFees } from "../../constants/stripe";
import { to2dp } from "../../utils/to2dp";
import { ReduxState } from "../types";

export interface BasketState extends ReduxState {
  data: {
      productOrders: ProductOrder[];
      quantity: number;
      subtotal: number;
      transactionFee: number;
      total: number;
  },
}
  

const initialState: ReduxState = {
  data: {
    productOrders: [],
    quantity: 0,
    subtotal: 0,
    transactionFee: 0,
    total: 0
  }
}

const basketSlice = createSlice({
  name: "basket",
  initialState,
  reducers: {
    updateBasket: (state, action: PayloadAction<ProductOrder>) => {
      const { payload } = action;
      let productOrders;
      if (state.data.productOrders.find(product => product.id === payload.id)) {
        if (payload.quantity === 0) {
          productOrders = state.data.productOrders.filter(product => product.id !== payload.id)
        }
        else {
          productOrders = state.data.productOrders.map((product, i) => product.id === payload.id ? payload : product)
        }
      }
      else {
        productOrders = [...state.data.productOrders, payload]
      }
      const subtotal = to2dp(productOrders.reduce((a, product) => a + product.totalCost, 0))
      const transactionFee = to2dp(subtotal * stripeFees.percentage + stripeFees.transaction)

      state.data = {
        productOrders,
        quantity: productOrders.reduce((a, product) => a + product.quantity, 0),
        subtotal,
        transactionFee,
        total: to2dp(subtotal + transactionFee)
      }
    },
    emptyBasket: (state) => {
      state.data = initialState.data;
    }
  },

});

export const { updateBasket, emptyBasket } = basketSlice.actions;
export default basketSlice.reducer;
