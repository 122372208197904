import { ActionReducerMapBuilder, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import TheGlassFactory from "../../../services/TheGlassFactory";
import { RootState } from "../../store";
import { ProductsState } from "..";
import { Product } from "../../../types/product";
import { orderBy } from "lodash";
import parseErrors from "../../utils/parseErrors";
import buildAsyncThunkReducer from "../../utils/buildAsyncThunkReducer";
import skipApiIfCached from "../../utils/skipApiIfCached";


const API = "getProducts"

export const getProducts = createAsyncThunk(
  API,
  async (params, { getState, rejectWithValue }) => {
    try {
      const { auth } = getState() as RootState

      const { data } =  await TheGlassFactory.request("GET", "/products", {
        authState: auth
      });

      return data
    } catch (error) {
      console.error(error);
      const parsedError = parseErrors(error, true)[0]
      return rejectWithValue(parsedError);
    }
  },
  {
    condition: (_, { getState }) => 
      skipApiIfCached(getState, API)
  }
);

export const getProductsReducer = (
  builder: ActionReducerMapBuilder<ProductsState>
) => {
  buildAsyncThunkReducer(
    builder,
    getProducts,
    (state: ProductsState, action: PayloadAction<Product[]>) => {
      state.api[API].isLoading = false;
      state.api[API].lastFetchTimestamp = Date.now();
      state.data.products = orderBy(action.payload, "createdAt", "desc")
    },
    API
  )
};
