import { createSlice } from "@reduxjs/toolkit";

import { Product } from "../../types/product";
import { getProductsReducer } from "./reducers/getProducts";
import { postProductReducer } from "./reducers/postProduct";
import { deleteProductReducer } from "./reducers/deleteProduct";
import { ReduxState } from "../types";
import { initialApiState } from "../constants";

export interface ProductsState extends ReduxState {
  data: {
      products: Product[];
  }
}

const initialState: ProductsState = {
  data: {
    products: []
  },
  api: {
    getProducts: initialApiState,
    postProduct: initialApiState,
    deleteProduct: initialApiState
  }
}

const productsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    getProductsReducer(builder)
    postProductReducer(builder)
    deleteProductReducer(builder)
  },

});

export default productsSlice.reducer;
