import { ActionReducerMapBuilder, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import TheGlassFactory from "../../../services/TheGlassFactory";
import { AuthState } from "..";
import parseErrors from "../../utils/parseErrors";
import buildAsyncThunkReducer from "../../utils/buildAsyncThunkReducer";

interface ConfirmOTP {
  userId: string;
  session: string;
  authCode: string;
}

const API = "confirmOTP";
export const confirmOTP = createAsyncThunk(
  API,
  async (data: ConfirmOTP, { rejectWithValue }) => {
    try {
      const { userId, session, authCode } = data
      const res = await TheGlassFactory.request("POST", "/confirmOTP", {
        payload: { userId, session, authCode },
        toast: true
      });
      return res.data
    } catch (error) {
      console.error(error);
      const parsedError = parseErrors(error, true)[0]
      return rejectWithValue(parsedError);
    }
  }
);

export const confirmOTPReducer = (
  builder: ActionReducerMapBuilder<AuthState>
) => {
  buildAsyncThunkReducer(
    builder,
    confirmOTP,
    (state: AuthState, action: PayloadAction<any>) => {
      state.api[API].isLoading = false;
      state.data.isAuthenticated = true
      state.data.accessToken = action.payload.accessToken
      state.data.refreshToken = action.payload.refreshToken
      state.data.tokenId = action.payload.tokenId
      state.data.user = action.payload.user
    },
    API
  )
};
