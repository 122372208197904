import React from "react";

import { useNavigate } from "react-router-dom";
import Button from "../../../../components/Button";

const GoBack: React.FC<{
  message: string;
}> = ({ message }) => {
  const navigate = useNavigate();

  return (
    <div className="center">
      <h3>{message}</h3>
      <br />
      <Button label="go back" onClick={() => navigate(-1)} isWide />
    </div>
  );
};

export default GoBack;
