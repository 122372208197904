import Button from "../../../Button";
import "./styles.scss";

interface Props {
  label: string;
  onClick: (e: React.FormEvent) => void;
}

const FormButton: React.FC<Props> = ({ label, onClick }) => (
  <Button label={label} onClick={onClick} isWide />
);

export default FormButton;
