import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../../redux/store";
import { register } from "../../../../../redux/auth/reducers/register";
import onDispatchFulfilled from "../../../../../redux/utils/onDispatchFulfilled";
import Form from "../../../../../components/Form";
import Loading from "../../../../../components/Loading";
import Input from "../../../../../components/Form/components/Input";
import FormButton from "../../../../../components/Form/components/FormButton";

const Register: React.FC<{}> = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const {
    api: {
      register: { isLoading },
    },
  } = useSelector((state: RootState) => state.auth);

  const [formData, setFormData] = useState({
    email: "",
    password: "",
    phoneNumber: "",
  });

  const onSubmit = async (e) => {
    e.preventDefault();

    try {
      const res = await dispatch(register(formData)).unwrap();
      onDispatchFulfilled(res, () => {
        navigate("/auth/confirm-register");
      });
    } catch (error) {
      console.error(error);
    }
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  if (isLoading) return <Loading />;
  return (
    <Form>
      <Input name="email" placeholder="Email" onChange={onChange} />
      <Input
        name="password"
        type="password"
        placeholder="Password"
        onChange={onChange}
      />
      <Input
        name="phoneNumber"
        placeholder="Phone Number"
        onChange={onChange}
      />
      <FormButton label="Register" onClick={onSubmit} />
      <span>
        Already have an account? <Link to="/auth/login">Login</Link>
      </span>
    </Form>
  );
};

export default Register;
