import React from "react";

import { Order } from "../../../../types/product";
import toGBP from "../../../../utils/toGBP";
import { useNavigate } from "react-router-dom";
import "./styles.scss";
import { toFullDateTime } from "../../../../utils/dates";

const OrderContainer: React.FC<{
  order: Order;
}> = ({ order }) => {
  const navigate = useNavigate();

  return (
    <div className="tile" onClick={() => navigate(`/orders/${order.id}`)}>
      <div className="info">
        <span>{toFullDateTime(order.createdAt)}</span>
        <span>Quantity: {order.quantity}</span>
      </div>
      <div className="cost">{toGBP(order.total)}</div>
    </div>
  );
};

export default OrderContainer;
