import { createSlice } from "@reduxjs/toolkit";

import { Order } from "../../types/product";
import { getOrdersReducer } from "./reducers/getOrders";
import { postOrderReducer } from "./reducers/postOrder";
import { updateOrderReducer } from "./reducers/updateOrder";
import { initialApiState } from "../constants";
import { ReduxState } from "../types";

export interface OrdersState extends ReduxState {
  data: {
    orders: Order[];
  }
}
  

const initialState: OrdersState = {
  data: {
    orders: []
  },
  api: {
    getOrders: initialApiState,
    postOrder: initialApiState,
    updateOrder: initialApiState
  }
}

const ordersSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    getOrdersReducer(builder)
    postOrderReducer(builder)
    updateOrderReducer(builder)
  },

});

// export const { logout } = authSlice.actions;
export default ordersSlice.reducer;
