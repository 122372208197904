import { useLocation } from "react-router-dom";

import "./styles.scss";

import { ReactComponent as ProfileSVG } from "../../assets/svgs/profile.svg";
import { ReactComponent as BeerSVG } from "../../assets/svgs/beer.svg";
import { ReactComponent as BasketSVG } from "../../assets/svgs/basket.svg";
import NavLink from "./components/NavLink";

const Nav: React.FC<{}> = () => {
  const location = useLocation();

  return (
    <nav>
      <NavLink
        toPath="/profile"
        currentPath={location.pathname}
        Svg={ProfileSVG}
      />
      <NavLink toPath="/" currentPath={location.pathname} Svg={BeerSVG} />
      <NavLink
        toPath="/checkout"
        currentPath={location.pathname}
        Svg={BasketSVG}
      />
    </nav>
  );
};

export default Nav;
