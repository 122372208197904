import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../redux/store";
import Loading from "../../../../components/Loading";
import Container from "../../../../components/Container";
import { getOrders } from "../../../../redux/orders/reducers/getOrders";
import OrderContainer from "../../components/Order";
import GoBack from "../../components/GoBack";

const Orders: React.FC<{}> = () => {
  const {
    data: orders,
    api: {
      getOrders: { isLoading, error },
    },
  } = useSelector((state: RootState) => state.orders);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(getOrders());
  }, [dispatch]);

  return (
    <Container header={<h1>Orders</h1>}>
      {isLoading ? (
        <Loading center error={error} />
      ) : orders.orders.length === 0 ? (
        <GoBack message="go make an order" />
      ) : (
        <div className="orders">
          {orders.orders.map((order, i) => (
            <OrderContainer key={i} order={order} />
          ))}
        </div>
      )}
    </Container>
  );
};

export default Orders;
